@charset "UTF-8";

.global {
  &__subTit {
    font-size:22px;
    @include font("medium");
    margin-bottom:20px;
  }
  &__tit {
    font-size:30px;
    @include font();
    text-align:center;
    margin-bottom:40px;
  }
  &__dotList {
    li {
      position:relative;
      padding-left:15px;
      &:before {
        content:"";
        display:inline-block;
        width:4px;
        height:4px;
        position:absolute;
        top:6px;
        left:0;
        background:#717171;
      }
    }
  }
}

.txtCnt {
  text-align:center !important;
}

.cRed {color:#f06146;}
.guide {
  font-size:16px;
  @include font("light");
}

html:lang(en) {
  .global {
    &__subTit {
      font-size:30px;@include font();
      &.-size20 {
        font-size:20px;
      }
    }
  }
}