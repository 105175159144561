@charset "UTF-8";
//심의회의 산하 위원회
.tabs {
    &.-comm {
        margin-bottom: 0;
    }
}
.tabsComm {
    width: 1000px;
    margin: 0 auto 50px;
    background: #f6f6f6;
    padding: 10px 30px;
    font-size: 0;
    &__item {
        display: inline-block;
        padding: 10px 0;
        font-size: 15px;
        &:after {
            content: "";
            width: 1px;
            height: 14px;
            background: #adadad;
            display: inline-block;
            margin:  0 28px;
            vertical-align: middle;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        &.-active {
            color: #3173e6;
        }
    }
}
.committee {
    font-size:18px;
    &__tit {
        font-size: 30px;
        font-weight: normal;
        margin-top: 50px;
    }
    &__subTit {
        font-size: 22px;
        @include font("medium");
        margin-top: 40px;
    }
    &__table {
        border-spacing: 0;
        width: 100%;
        margin-top: 20px;
        text-align: center;

        thead {
            tr.center {
                th {
                    text-align:center;
                    padding-left:0;
                    &:not(:first-of-type){
                        border-left:1px solid #fff !important;
                    }
                }
            }
            tr:first-child {
                th {
                    background: #2497a4;
                    color: #fff;
                    a {color:#fff;}
                    &:first-of-type {border-top-left-radius:10px;}
                    &:last-of-type {border-bottom-right-radius:10px;border-left:1px solid #2497a4}
                    &.center {
                        text-align:center;
                        padding-left:0;
                        &:not(:first-of-type){
                            border-left:1px solid #fff;
                        }
                    }
                }
            }
            th {
                height: 40px;
                text-align: center;
                background: #f6f6f6;
                font-weight: normal;
            }
        }
        tbody {
            td {
                height: 50px;
                border-bottom:1px solid #d6d6d6;
            }
            .descTxt {
                text-align: left;
                td {
                    border-bottom: 0;
                }
            }
        }
        &.-left {
            tbody {
                td {
                    text-align:left;
                    padding-left:30px;
                    &:not(:first-of-type) {
                        border-left:1px solid #ccc;
                    }
                    &.center {
                        text-align:center;
                        padding-left:0;
                    }
                }

            }
        }
    }
    &__table + &__table {
        margin-top: 50px;
    }
    .bolList {
        margin-top: 20px;
        &__item {
            position: relative;
            padding-left: 15px;
            font-size: 18px;
            line-height: 32px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 2px;
                top: 14px;
                width: 4px;
                height: 4px;
                background: #717171;
            }
        }
        &.-noStyle {
            .bolList__item {
                padding-left:0;
                &:before {display:none}
            }
        }
    }
    .iconArrowTit {
        display: inline-block;
        margin:0 10px;
    }

}

html:lang(en) {
    .committee__subTit {@include font()}
}