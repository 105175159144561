@charset "UTF-8";

@import "/font/NotoKR-Black/stylesheet.css";
@import "/font/NotoKR-Bold/stylesheet.css";
@import "/font/NotoKR-Regular/stylesheet.css";
@import "/font/NotoKR-Medium/stylesheet.css";
@import "/font/NotoKR-Light/stylesheet.css";
@import "/font/NotoKR-DemiLight/stylesheet.css";
@import "/font/NotoKR-Thin/stylesheet.css";


@import "common/reset";
@import "common/function";
@import "common/mixin";

@import "vendor/sprite";
@import "common/layout";
@import "common/global";


//모듈
@import "module/btnGroup";
@import "module/subVisual";
@import "module/layerPopup";
@import "module/bbsThum";
@import "module/bbsListTop";
@import "module/tabs";
@import "module/grayBox";

//페이지
@import "page/main";
@import "page/bbs";
@import "page/orgcht";
@import "page/intro";
@import "page/propse";
@import "page/infoList";
@import "page/map";
@import "page/committee";
@import "page/greeting";
@import "page/hist";
@import "page/infoMi";

@import "common/common";

//오류수정
@import "page/browerFix";
