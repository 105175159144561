@charset "UTF-8";

.greeting {
   @include clearFix();
   margin-top:35px;
   &__tit {
     font-size:44px;
     @include font("light");
     &.-blue {
       color:#3d7eeb;
       margin-top:5px;
     }
   }
   &__right {
     float:right;
     margin-left:40px;
   }
   &__txt {
     font-size:18px;
     @include font("light");
     line-height:1.8;
     word-break: keep-all;
     &:not(:first-of-type) {
       margin-top:40px;
     }
   }
   &__thum {vertical-align: top}
   &__thumTxt {
     font-size:26px;
     @include font("medium");
     margin-top:10px;
     small {
       font-size:16px;
       @include font();
     }
   }
 }

html:lang(en) {
  .greeting {
    &__txt {
      font-size:16px;
    }
    &__thumTxt {
      text-align:right;
      font-size:20px;
      height:95px;
      small {
        font-size:14px;
        line-height:1.2;
      }
    }
  }
}