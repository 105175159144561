@charset "UTF-8";
//color
$cBlue:#367df3;
html,body {width:100%;height: 100%;min-width:1220px;}

.layout-top {
  min-width:1220px;
  padding:0 10px;
  height:80px;
  margin:0 auto;
  position:fixed;
  left:0;
  top:0;
  background:#fff;
  width:100%;
  z-index: 1500;
  border-bottom:1px solid #d4d4d4;
  box-shadow: 0 3px 5px rgba(0,0,0,0.1);
  &__title {
    position:absolute;
    left:0;
    top:50%;
    margin-top:-21px;
    vertical-align: top;
  }
  &__wrap {
    @include before-middle;
    text-align:center;
    vertical-align: middle;
    position:relative;
    height:80px;
  }
}

.gnb {
  display:inline-block;
  text-align:center;
  vertical-align: middle;
  font-size:0;
  a {
    display:inline-block;
    font-size:20px;
    position:relative;
    &:after {
      content:"";
      display:block;
      width:100%;
      height:2px;
      background:#fff;
      position:absolute;
      left:0;
      bottom:-15px;
      transition: all .3s;
    }
    &:hover,&.active {
      color:#3173e6;
      &:after {
        background:#3173e6;
        bottom:-9px;
      }
    }
    &+a {margin-left:70px}
  }
}
.layout-top {
  &__right {
    position:absolute;
    right:0;
    top:50%;
    margin-top:-20px;
    font-size:0;
  }
  &__sns--btn {
    display:inline-block;
    width:40px;
    height:42px;
    line-height:42px;
    text-align:center;
    border:1px solid #367df3;
    border-radius:15px 0;
    vertical-align: top;
    margin-right:5px;
    span {
      display:inline-block;
      vertical-align: middle;
      @include txtHidden();
    }
  }
}
.btnChange__lang {
  color:$cBlue;
  font-size:12px;
  line-height:40px;
  border:1px solid $cBlue;
  width:130px;
  border-radius:15px 0;
  display:inline-block;
  &:before {
    content:"";
    display:inline-block;
    @extend .iconWorld;
    vertical-align: sub;
    margin-right:5px;
  }
}

.layout-footer {
  background:#f2f2f0;
  &__tit {
    font-size:20px;
    height:40px;
    @include font("medium");
    color:#4b4b4a;
    @at-root .layout-footer {
      .layout-footer__tit a {
        font-size:20px;
        height:40px;
        @include font("medium");
        color:#4b4b4a;
        &:before {display:none}
      }
    }
  }
  &__link {
    width:1200px;
    margin:0 auto;
    //height:325px;
    overflow:hidden;
    padding:40px 0;
    &--item {
      float:left;
      width:20%;
      @at-root html:lang(en) .layout-footer__link--item {width:25%}
      a {
        font-size:14px;
        display:block;
        @include font("light");
        color:#717171;
        &:before {
          content:"";
          display:inline-block;
          width:4px;
          height:4px;
          background:#aeaead;
          position:relative;
          top:-4px;
          margin-right:5px;
        }
        &+a {margin-top:10px;}
      }
      &.-float {
        a {
          float:left;
          width:40%;
          margin-top:0;
          margin-bottom:10px;
        }
      }
    }
  }
  &__etcLink {
    width:230px;
    float:right;
    h2 {
      font-size:16px;
      @include font("dLight");
      color:#313131;
    }
    .selectBox {
      width:230px;
      margin-top:8px;
      &+h2 {margin-top:19px}
    }
  }
  &__contents {
    width:100%;
    background:#fff;
  }
  &__inner {
    padding:40px 0 100px;
    width:1200px;
    margin:0 auto;
    img {vertical-align: top};
  }
  &__address {
    display:inline-block;
    margin-left:15px;
  }
  &--copyright {
    font-size:10px;
    color:#717171;
    text-transform: uppercase;
    margin-top:5px;
  }
  &--address {
    font-size:13px;
    color:#717171;
    @include font("dLight");
  }
  &.subFooter {
    margin-top:120px;
    .layout-footer {
      &__etcLink {display:none}
      &__link {
        width:1000px;
        &--item {
          width:25%;
          &.-float {
            width:30%;
            &+.layout-footer__link--item {width:20%}
          }
        }
      }
      &__inner {width:1000px;}
    }
  }
}





//서브 페이지
.subContents {
  width:1000px;
  margin:0 auto;
}



.fullGnb {
  max-width:100%;
  width:100%;
  position:absolute;
  top:80px;
  left:0;
  background:#fff;
  z-index:1000;
  border-bottom:1px solid #dce3e8;
  text-align:center;
  display:none;
  &__wrap {
    display:inline-block;
    text-align:left;
    padding:30px 0 50px;
    font-size:0;
  }
  &__tit {
    font-size:40px;
    @include font();
    display:inline-block;
  }
  &__group {
    display:inline-block;
    margin-left:60px;
    vertical-align: top;
    &.empty {
      margin-left:40px;
      .fullGnb__sub {color:#fff;opacity: 0}
    }
  }
  &__sub {
    font-size:22px;
    @include font("dLight");
    margin-bottom:17px;
    margin-top:3px;
  }
  &__link {
    font-size:16px;
    margin-bottom:8px;
    display:block;
    &.-emptyTit {margin-top:45px}
    &--th {
      font-size:14px;
      margin-bottom:8px;
      a {color:#717171;}
      li {
        text-indent: -10px;
        padding-left:15px;
        &+li {margin-top:5px;}
        &:before  {
          content:"";
          display:inline-block;
          width:4px;
          height:4px;
          vertical-align: middle;
          background:#717171;
          margin-right:5px;
        }
      }
    }
    @at-root html:lang(en) {
      .fullGnb {
        &__link {
          display: inline-block;
          @include font("dLight");
          font-size: 18px;
          &:before {
            content:"";
            display:inline-block;
            width:4px;
            height:4px;
            position:relative;
            background:#717171;
            vertical-align: middle;
            margin-right:5px;
            top:-2px;
          }
          &:not(:first-child) {
            margin-left: 40px;
          }
        }
      }
    }
  }
  &__menu {
    display:none;
    &.active {
      display:inline-block;
    }
  }
  &.active {
    display:block;
  }
  a {
    position:relative;
    transition: all .3s;
    &:hover {
      color:$cBlue;
    }
  }
}


html:lang(en) {
  .layout-footer {margin-top:150px}
  .layout-top__title {
    margin-top:-18px;
    img {vertical-align: middle}
  }
  .layout-footer__link {width:1000px;}
  .mainNav+.layout-footer .layout-footer__link {width:1200px}
  .fullGnb__wrap {padding:34px 0;}
  .layout-footer.subFooter .layout-footer__link--item {width:32%}
  .layout-footer__inner {width:1000px}
  .mainNav+.layout-footer .layout-footer__inner {width:1200px}
}




