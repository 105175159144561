@charset "UTF-8";

.propse {
  &__info {
    width:1000px;
    margin:40px auto 0;
  }
  &__infoItem {
    font-size:14px;
    @include font("light");
    vertical-align: middle;
    position:relative;
    padding-left:10px;
    line-height:1.5;
    &:before {
      content:"";
      display:block;
      width:5px;
      height:5px;
      background:#fff;
      position:absolute;
      left:0;
      top:8px;
    }
  }
}

.propseSection {
  &__tit {
    font-size:22px;
    @include font("medium");
    margin:40px 0 15px;
    overflow:hidden;
    line-height:1;
  }
  &__guide {
    margin-top:10px;
    float:right;
    font-size:14px;
    @include font("light");
  }
  &__info {
    overflow:hidden;
    width:100%;
    margin-top:15px;
    .propseSection__chkLabel {float:right;}
  }
  &__txt {
    float:left;
    font-size:14px;
    color:#313131;
    @include font("light");
  }
  &__chkInp:checked+.propseSection__chkLabel:after {
    content:"";
    position:absolute;
    left:5px;
    top:4px;
    width:10px;
    height:10px;
    background:#5673bc;
    border-radius:50%;
    font-size:0;
    margin-top:1px;
  }
  &__chkLabel {
    font-size:18px;
    position:relative;
    padding-left:25px;
    &:before {
      content:"";
      display:block;
      position:absolute;
      left:0;
      width:18px;
      height:18px;
      border:1px solid #363636;
      border-radius:50%;
      font-size:0;
    }
  }
  &+.btnGroup {margin-top:70px;}
  &__table {
    width:100%;
    border-collapse: collapse;
    border-top:2px solid #3173e6;
    ul {
      font-size:14px;
      li {line-height:24px;}
    }
    th {
      background:#f6f6f6;
      font-size:18px;
      text-align:left;
      height:60px;
      vertical-align: middle;
    }
    thead {
      th {
        text-align:center;
        border-right:1px solid #dfe0e1;
        border-bottom:1px solid #dfe0e1;
        @include font();
        &:last-child {
          border-right:none;
        }
      }
    }
    tbody {
      th {
        text-align:left;
        padding-left:30px;
        border-bottom:1px solid #dfe0e1;
        @include font();
        .cRequired {
          margin-left:-15px;
        }
      }
      td {
        border-right:1px solid #dfe0e1;
        border-bottom:1px solid #dfe0e1;
        height:60px;
        padding:10px 25px;
        &:last-child {
          border-right:none;
        }
        input {
          height:39px;
          line-height:39px;
          padding:0 10px;
          width:100%;
          border:1px solid #c5c7c9;
          &[type="checkbox"] {
            height:auto;
            line-height:1;
            padding:0 5px 0 0;
            width:auto;
            border:none;
          }
          &[type="file"] {
            height:auto;
            line-height:1;
            padding:5px 0;
            width:90%;
          }
        }
      }
    }
    &.-center {
      td {text-align:center;padding:0;}
    }
  }
}

.cRequired {
  color:#f06146;
  vertical-align: middle;
  @include font();
  &.-error {
    font-size:14px;
    margin:20px 20px 0 0;
    float:left;
    color:#717171;
  }
}

.checkLabelList {
  width: 320px;
  margin: 20px 20px 0 0;
  float: left;
  color: #717171;
  &:nth-of-type(3), &:nth-of-type(4) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &__dd {
    font-size: 15px;
    margin-top: 15px;
    @include font("dLight")
  }
  .propseSection {
    &__chkInp:checked + label {
      color: #313131;
      @include font("medium");
      & + .checkLabelList__dd {
        color: #313131
      }
      &:after {
        top: 7px
      }
    }
  }
}

.propseCompleted {
  text-align:center;
  margin-top:90px;
  &__tit {
    font-size:54px;
    @include font("light");
    color:#3173e6;
    line-height:1;
    &:before {
      content:"";
      display:block;
      width:111px;
      height:111px;
      background:url("/images/prpare/img_complete.png") no-repeat 0 0;
      margin:0 auto 30px;
    }
  }
  &__txt {
    margin-top:20px;
    font-size:18px;
    line-height:28px;
    @include font("dLight")
  }
}

.mgTop60 {margin-top:60px}

.propse__view {
  &.-tit {
    font-size:22px;
    color:#3173e6;
    border-bottom:2px solid #3173e6;
    display:inline-block;
    margin-bottom:30px;
    line-height:1;
    @include font("dLight");
    &:after {
      content:"";
      display:block;
    }
  }
  &.-txt {
    margin-bottom:50px;
    @include font("dLight");
    font-size:18px;
    p:not(:first-of-type) {
      margin-top:50px;
    }
  }
}

.propseAnswer {
  margin-top:70px;
  border:1px solid #2497a4;
  border-radius:20px;
  padding:50px 30px;
  position:relative;
  &:before {
    content:"";
    display:block;
    position:absolute;
    width:28px;
    height:24px;
    background:#fff url("/images/prpare/img_top_arrow.png") no-repeat 0 0;
    top:-24px;
    left:60px;
  }
  &__tit {
    font-size:30px;
    color:#2497a4;
    line-height:1;
    margin-bottom:20px;
    @include font();
    &:before {
      content:"";
      display:inline-block;
      width:38px;
      height:35px;
      background:url("/images/prpare/iconReplay.png") no-repeat 0 0;
      margin-right:5px;
      vertical-align: sub;
    }
  }
  &__txt {
    p:not(:first-of-type) {
      margin-top:40px;
    }
  }
}

.captcha {
  overflow: hidden;
  &__img {
    float:left;
    margin-top:8px;
    width:225px;
    height:45px;
    img {width:100%;height:100%;vertical-align: top}
  }
  &__right {
    float:right;
    width:calc(100% - 253px);
  }
  &__inp {
    width:250px !important;
    height:40px !important;
  }
  &__btn {
    width:168px;
    height:40px;
    display:inline-block;
    background:#9e9e9e;
    color:#fff;
  }
  &__guide {
    font-size:15px;
    @include font("dLight");
    margin-bottom:5px;
  }
}