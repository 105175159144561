@charset "UTF-8";

.bbsListTop {
  width:1000px;
  margin:0 auto 60px;
  border-bottom:2px solid #3d7eeb;
  text-align:center;
  &__wrap {
    display:inline-block;
    text-align:left;
    margin-bottom:30px;
    height:140px;
    position:relative;
    @include before-middle();
  }
  &__tit {
    display:inline-block;
    vertical-align: middle;
    font-size:18px;
    line-height:1.5;
    @include font("dLight");
  }
  &.-meeting {
    .bbsListTop {
      &__tit {
        padding-left:160px;
        &:before {
          content:"";
          display:block;
          font-size:0;
          width:140px;
          height:140px;
          vertical-align: middle;
          background:url("/images/activity/icon_metting.png") no-repeat 0 0;
          position:absolute;
          left:0;top:0;
        }
      }
    }
  }
}