@charset "UTF-8";

.bbsThum {
  overflow:hidden;
  &__wrap {
    margin-left:-30px;
    overflow:hidden;
    width:1030px;
  }

  &__item {
    display:block;
    width:313px;
    height:330px;
    margin-left:30px;
    float:left;
  }
  &__thum {
    background-position:center center;
    background-repeat: no-repeat;
    background-size:cover;
    width:313px;
    height:210px;
    position:relative;
    overflow:hidden;
    &:before {
      content:"";
      position:absolute;
      width:100%;
      height:100%;
      z-index: 1;
      left:0;top:0;
      background:rgba(93,93,125,0.1);
    }
  }
  &__tit {
    font-size:18px;
    @include font("dLight");
    @include ellipsis_multi();
    margin-top:15px;
  }
  &__data {
    margin-top:10px;
    font-size:13px;
    @include font("dLight");
    color:#717171;
  }
  &__frame {
    position:relative;
    padding-bottom:56.25%;
    padding-top:25px;
    height:0;
    margin:-50px 0 50px;
    iframe {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
  &__type {
    color:#fff;
    position:absolute;
    left:0;
    top:0;
    width:67px;
    height:30px;
    line-height:30px;
    font-size:13px;
    text-align:center;
    z-index: 10;
    &.-blue {
      background:#3d7eeb;
    }
    &.-green {
      background:#a8b51a;
    }
  }
  &.-video {
    .bbsThum__thum {
      &:before {
        background:rgba(0,0,0,0.3)
      }
      &:after {
        content:"";
        position:absolute;
        @include iconPlay();
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
        z-index: 2;
      }
    }
  }
}

