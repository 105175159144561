@charset "UTF-8";

.layerPopup {
  display:none;
 position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.4);
  text-align:center;
  @include before-middle();
  min-width:1000px;
  z-index: 2010;
  &__layer {
    display:inline-block;
    vertical-align: middle;
    background:#fff;
    border-radius:15px 0 15px;
    overflow:hidden;
    min-width:600px;
    padding:40px;
    position:relative;
  }
  &__pwd {
    display:block;
    width:350px;
    line-height:40px;
    height:40px;
    border:1px solid #b8bdc0;
    margin:20px auto 40px;
    padding:0 10px;
  }
  &__tit {
    font-size:32px;
    @include font();
    margin-bottom:24px;
  }
  &__txt {
    font-size:17px;
    line-height:1.6;
    @include font("light");
  }
  &.-info {
    .layerPopup {
      &__layer {
        text-align:left;
        width:940px;
      }
      &__txtWrap {
        overflow-y: scroll;
        height: 500px;
      }
      &__txt {
        margin-bottom: 40px;
      }
    }
  }
  &__close {
    display:block;
    position:absolute;
    right:30px;
    top:30px;
  }
  }

@media all and (max-height:768px) {
  .layerPopup.-info {
    .layerPopup__txt {max-height:180px}
  }
}