@mixin bg_select() {
    width: 13px;
    height: 7px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-36px -132px;
}
@mixin btnWhiteClose() {
    width: 31px;
    height: 31px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-36px -96px;
}
@mixin iconArrowTit() {
    width: 9px;
    height: 21px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-74px -58px;
}
@mixin iconBannerNext() {
    width: 33px;
    height: 33px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -58px;
}
@mixin iconBannerPrev() {
    width: 34px;
    height: 33px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-58px 0px;
}
@mixin iconBbsList() {
    width: 12px;
    height: 20px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-153px -106px;
}
@mixin iconBbsListActive() {
    width: 12px;
    height: 20px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-97px -50px;
}
@mixin iconFaceBook() {
    width: 8px;
    height: 16px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-170px -106px;
}
@mixin iconFile() {
    width: 13px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-97px -75px;
}
@mixin iconFile_over() {
    width: 13px;
    height: 15px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -144px;
}
@mixin iconFirstPage() {
    width: 12px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-35px -144px;
}
@mixin iconLastPage() {
    width: 12px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-18px -144px;
}
@mixin iconLeftArrow() {
    width: 31px;
    height: 7px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -132px;
}
@mixin iconMouse() {
    width: 22px;
    height: 33px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-125px -50px;
}
@mixin iconNavNaver() {
    width: 19px;
    height: 26px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-153px -30px;
}
@mixin iconNewMore() {
    width: 30px;
    height: 30px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-107px -96px;
}
@mixin iconNewMore_over() {
    width: 30px;
    height: 30px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-72px -96px;
}
@mixin iconNext() {
    width: 23px;
    height: 45px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-125px 0px;
}
@mixin iconNextPage() {
    width: 7px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-64px -144px;
}
@mixin iconPlay() {
    width: 53px;
    height: 53px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px 0px;
}
@mixin iconPrev() {
    width: 23px;
    height: 45px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-97px 0px;
}
@mixin iconPrevPage() {
    width: 7px;
    height: 11px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-52px -144px;
}
@mixin iconRightArrow() {
    width: 31px;
    height: 9px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-58px -38px;
}
@mixin iconSearch() {
    width: 25px;
    height: 25px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-153px 0px;
}
@mixin iconWorld() {
    width: 17px;
    height: 16px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-153px -85px;
}
@mixin iconX() {
    width: 31px;
    height: 31px;
    background-image: url("/images/sprite/sprite.png");
    background-position:0px -96px;
}
@mixin icon_dwn() {
    width: 17px;
    height: 19px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-153px -61px;
}
@mixin test() {
    width: 31px;
    height: 31px;
    background-image: url("/images/sprite/sprite.png");
    background-position:-38px -58px;
}

.bg_select {
    @include bg_select;
}
.btnWhiteClose {
    @include btnWhiteClose;
}
.iconArrowTit {
    @include iconArrowTit;
}
.iconBannerNext {
    @include iconBannerNext;
}
.iconBannerPrev {
    @include iconBannerPrev;
}
.iconBbsList {
    @include iconBbsList;
}
.iconBbsListActive {
    @include iconBbsListActive;
}
.iconFaceBook {
    @include iconFaceBook;
}
.iconFile {
    @include iconFile;
}
.iconFile_over {
    @include iconFile_over;
}
.iconFirstPage {
    @include iconFirstPage;
}
.iconLastPage {
    @include iconLastPage;
}
.iconLeftArrow {
    @include iconLeftArrow;
}
.iconMouse {
    @include iconMouse;
}
.iconNavNaver {
    @include iconNavNaver;
}
.iconNewMore {
    @include iconNewMore;
}
.iconNewMore_over {
    @include iconNewMore_over;
}
.iconNext {
    @include iconNext;
}
.iconNextPage {
    @include iconNextPage;
}
.iconPlay {
    @include iconPlay;
}
.iconPrev {
    @include iconPrev;
}
.iconPrevPage {
    @include iconPrevPage;
}
.iconRightArrow {
    @include iconRightArrow;
}
.iconSearch {
    @include iconSearch;
}
.iconWorld {
    @include iconWorld;
}
.iconX {
    @include iconX;
}
.icon_dwn {
    @include icon_dwn;
}
.test {
    @include test;
}
