@charset "UTF-8";
$borderColor:#d6d6d6;
.hist {
  @include clearFix();
  margin-top:55px;
  position:relative;
  &:before {
    content:"";
    display:block;
    position:absolute;
    left:204px;
    width:1px;
    background:$borderColor;
    top:63px;
    bottom:63px;
  }
  &__tit {
    font-size:32px;
    margin-bottom:10px;
    @include font("dLight");
    &.-blue {color:#3d7eeb}
    &+.hist__subTxt {margin-top:10px;}
  }
  &__subTxt {
    font-size:18px;
    line-height:1.5;
  }
  &__item {
    font-size:0;
    display:block;
    &:not(:first-of-type) {
      margin-top:20px;
    }
    &:first-of-type {
      .hist {
        &__year {
          background:#3173e6;
          color:#fff;
          &:before,&:after {
            background:#3173e6;
          }
        }
      }
    }
  }
  &__year {
    font-size:26px;
    display:inline-block;
    width:126px;
    height:126px;
    line-height:126px;
    text-align: center;
    vertical-align: top;
    background:#f6f6f6;
    border-radius:50%;
    @include font("medium");
    position:relative;
    margin-right:84px;
    &:before {
      content:"";
      width:84px;
      height:1px;
      background:$borderColor;
      display:block;
      position:absolute;
      right:-84px;
      top:62px;
    }
    &:after {
      content:"";
      display:block;
      position:absolute;
      top:50%;
      transform: translateY(-50%);
      right:-84px;
      width:10px;
      height:10px;
      background:#d6d6d6;
      border-radius:50%;
    }
  }
  &__infoBox {
    display:inline-block;
    vertical-align: top;
    margin:53px 0 0 20px;
  }
  &__info {
    position:relative;
    padding-left:80px;
    padding-top:3px;
  }
  &__mons {
    font-size:20px;
    @include font("medium");
    display:inline-block;
    width:43px;
    text-align:right;
    position:absolute;
    left:0;
    top:0;
  }
  &__txt {
    overflow:hidden;
    font-size:18px;
    line-height:20px;
    max-width:690px;
    @include font("dLight");
    &:not(:first-of-type) {
      margin-top:10px;
    }
  }
}

html:lang(en) {
  .hist {
    &__tit {
      @include font("light");
      font-size:30px;
      &+.hist__subTxt {margin-top:20px}
    }
    &__subTxt {
      font-size:16px;
      @include font("dLight")
    }
    &__year {
      font-size:25px;
    }
    &__txt {
      font-size:16px;
    }
  }
}