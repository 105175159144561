@charset "UTF-8";
//공통
legend {@include txtHidden()}
label {cursor:pointer}


//설렉트박스
.selectBox {
  border:1px solid #dadbdc;
  display:inline-block;
  vertical-align: middle;
  background:#fff;
  label {
    display:inline-block;
    font-size:15px;
    line-height:33px;
    @include font("light");
    margin:0 48px 0 15px;
    width:75%;
    @include ellipsis();
  }
  select {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 1;
    opacity: 0;
    font-size:15px;
    line-height:33px;
    @include font("light");
    padding-left:15px;
  }
  &__wrap {
    position:relative;
    &:after {
      content:"";
      display:block;
      @extend .bg_select;
      position:absolute;
      right:13px;
      top:50%;
      transform: translateY(-50%);
    }
  }
}


.searchBox {
  margin:0 auto 50px;
  overflow:hidden;
  font-size:0;
  text-align:center;
  form {
    display:inline-block;
    vertical-align: middle;
  }
  &:before {
    content:"";
    display:inline-block;
    @extend .iconSearch;
    vertical-align: middle;
  }
  &__inp {
    border:none;
    line-height:40px;
    height:40px;
    font-size:16px;
    display:inline-block;
    border-bottom:1px solid $cBlue;
    width:392px;
    margin-left:10px;
    padding-left:5px;
    @include font("dLight");
  }
  &__submit {
    display:inline-block;
    width:80px;
    line-height:40px;
    color:#fff;
    background:#5673bc;
    border-radius:15px 0;
    font-size:16px;
    margin-left:10px;
  }
  &__selectBox {
    border:none;
    border-bottom:1px solid #3d7eeb;
    margin-left:5px;
    label {
      line-height:40px;
      text-align:left;
      margin-left:0;
      padding-left:10px;
      @include font("dLight");
    }
    select {padding-right:15px}
    .selectBox__wrap:after {right:5px}
  }
}


//paging
.paging {
  margin-top:35px;
  text-align:center;
  vertical-align: middle;
  position:relative;
  font-size:0;

  a {
    display:inline-block;
    width:50px;height:50px;line-height:50px;
    font-size:14px;
    color:#717171;
    text-align:center;
    vertical-align: middle;
    border:1px solid #d4d4d4;
    border-radius:3px;
    &:not(:first-of-type){
      margin-left:5px;
    };
    &.active {
      background:#5673bc;
      border-color:#5673bc;
      color:#fff;
    }
    &+.paging__nav {

    }
  }
  .paging__nav+a {}
  .paging__nav+.paging__nav {}
  [class*="icon"] {
    overflow:hidden;
    text-indent:-100%;
    display:inline-block;
    font-size:0;
  }
  &+.btnGroup {
    margin-top:50px;
  }
}





// 에러페이지

.errorWrap {
  @include before-middle();
  height: 100%;
  width: 100%;
  text-align: center;
}
.error {
  text-align: center;
  width: 638px;
  margin: 0 auto;
  display: inline-block;
  &__logo {
    text-align: left;
  }
  .errorBox {
    display: inline-block;
    vertical-align: middle;
    box-shadow:7px 14px 70px 9px #ebebeb;
    margin-top: 15px;
  }
  &__tit {
    background: #095db1;
    width: 100%;
    color: #fff;
    height: 110px;
    font-size: 0;
    @include before-middle();
    vertical-align: middle;
    img {vertical-align: middle;margin-right:5px;}
    span {
      vertical-align: middle;
      font-size:34px;
      @include font();
    }
  }
  &__con {
    padding: 59px 84px 40px 84px;
    color: #717171;
    line-height: 32px;
  }
  &__text {
    font-size: 18px;
    &.-em {
      font-size: 20px;
    }
  }
  .errorBtn {
    width: 179px;
    height: 50px;
    display: inline-block;
    font-size: 16px;
    border-radius: 18px 0;
    padding-top: 15px;
    margin: 0 3px 40px;
    transition: all 300ms;
    &:hover {
      border-radius:0 15px;
    }
    &.-white {
      background: #fff;
      border: 1px solid #313131;
      color: #313131;
    }
    &.-blue {
      background: #5673bc;
      border: none;
      color: #fff;
    }
  }
}

.txtArea {
  width:100%;
  height:150px;
  overflow-y:auto;
  resize:none;
  border:1px solid #c5c7c9;
  padding:10px 10px 20px 10px;
  &__count {
    text-align:right;
    margin-top:10px;
    font-size:14px;
    @include font("light")
  }
}

.inpFile {
  &__btn {
    display:inline-block;
    width:114px;
    line-height:40px;
    vertical-align: middle;
    height:40px;
    color:#fff;
    background:#9e9e9e;
    text-align:center;
    border-radius:15px 0 15px;
  }
  &__txt {
    font-size:13px;
    @include font("light");
    display:inline-block;
    margin-left:5px;
  }
  &__del {
    display:inline-block;
  }
  &__addTxt {
    display:inline-block;
    font-size:18px;
    @include font("dLight");
  }
  &__add {
    margin-top:15px;
    &.remove {
      display:none;
    }
    &:first-of-type {
      margin-top:20px;
    }
  }
}

.bgBlue {background:#5673bc;border-color:#5673bc;color:#fff;}
.bgDarkBlue {background:#246ca2;border-color:#246ca2;color:#fff;}
.bgDarkGreen {background:#a8b51a;border-color:#a8b51a;color:#fff}
.bgRed {color:#fff;background:#f06146;border-color:#f06146;}
.bgYellow {color:#fff;background:#f1a606;border-color:#f1a606;}




