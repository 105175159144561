@charset "UTF-8";
.infoMi {
  overflow: hidden;
  margin-bottom:70px;
  &__left {
    width:550px;
    float:left;
    font-size:18px;
    @include font("dLight")
  }
  &__tit {
    font-size:30px;
    margin-bottom:20px;
    @include font("dLight");
    color:#313131;
  }
  &__txt {
    line-height:1.5;
  }
  &__right {
    float:right;
    width:390px;
  }
}
.imgMi {
  display:inline-block;
  margin-bottom:85px;
  &.-total {margin-bottom:30px}
}
.infoMiDwn {
  &__tit {
    font-size:18px;
    float:left;
    @include font();
    line-height:50px;

  }
  &__list {
    float:right;
    li {
      float:left;
      &+li {margin-left:10px}
    }
  }
  &__btn {
    display:block;
    width:180px;
    height:50px;
    line-height:50px;
    text-align:center;
    color:#fff;
    background:#5673bc;
    font-size:16px;
    border-radius: 15px 0 15px 0;
    i {display:inline-block;margin-right:5px;}
  }
}




