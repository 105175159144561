@charset "UTF-8";
.intro {
  margin-top:80px;
  &__header {
    width:100%;
    height:540px;
    @include before-middle();
    text-align:center;
    background:url("/images/info/bg_intro.png") no-repeat center center;
    background-size:cover;
  }
  &__wrap {
    display:inline-block;
    vertical-align: middle;
    color:#fff;
    text-align:left;
    width:1000px;
  }
  &__tit {
    font-size:22px;
    height:32px;
    @include font("bold");
    border-bottom:1px solid #fff;
    display:inline-block;
  }
  &__commt {
    margin-top:25px;
    font-size:50px;
    @include font("light");
  }
  &__orign {
    margin-top:20px;
    font-size:18px;
    @include font("light");
  }
  &__group {
    width:1000px;
    margin:40px auto 0;
    overflow:hidden;
    .floatLeft,.floatRight {
      width:470px;
    }
  }
  &__sub {
    font-size:40px;
    @include font("light");
    color:#3173e6;
    height:50px;
    display:inline-block;
    border-bottom:1px solid #3173e6;
    margin-bottom:38px;
  }
  &__txt {
    font-size:18px;
    @include font("dLight");
    line-height:1.6;
    small {font-size:14px;}
  }
  &__img {
    vertical-align: top;
    text-align:center;
    display:block;
    width:786px;
    margin:0 auto 80px;
  }
  &__prvate {
    margin-top:30px;
    background:url("/images/info/bg_intro_01.png") no-repeat center left;
    padding:30px 0 30px 160px; ;
  }
  &__gvrn {
    margin-top:20px;
    background:url("/images/info/bg_intro_02.png") no-repeat center left;
    padding:30px 0 30px 160px; ;
  }
  .dotList+&__th {
    margin-top:40px;
  }
}


.dotList {
  li {
    font-size:18px;
    @include font("dLight");
    line-height:1.7;
    position:relative;
    padding-left:14px;
    &:before {
      content:"";
      display:block;
      left:0;
      top:13px;
      width:4px;height:4px;
      vertical-align: middle;
      background:#717171;
      position:absolute;
    }
  }
}
.floatLeft {
  float:left;
}
.floatRight {
  float:right;
}

strong {
  @include font("bold");
}

html:lang(en) {
  .intro__orign {line-height:1.5;}
  .intro__th {font-size:20px;}
}