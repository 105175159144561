@charset "UTF-8";
//조직도
.orgcht {
  text-align:center;
  width:1000px;
  margin:80px auto 0;
  &__tit {
    font-size:30px;
    @include font();
  }
  &__nmpr {
    margin-top:20px;
    font-size:0;
    text-align:center;
    @include font("light");
    span {
      display:inline-block;
      font-size:36px;
      &+span {
        margin-left:35px;
      }
    }
  }
  &__img {
    text-align:center;
    vertical-align: top;
    margin-top:35px;
  }

  &__table {
    width:100%;
    border-spacing: 0;
    overflow:hidden;
    margin-top:20px;
    thead {
      background:#2497a4;
      th {
        background:#2497a4;
        color:#fff;
        height:40px;
        @include font("medium");
        &:first-of-type {border-radius:10px 0 0 0;}
        &:last-of-type {border-radius:0 0 10px 0;border-left:1px solid #2497a4}
      }
    }
    tbody {
      td {
        height:50px;
        border-bottom:1px solid #d6d6d6;
      }
    }
  }
}

.subTit {
  text-align:left;
  color:#313131;
  font-size:22px;
  @include font("medium");
  margin-top:50px;
}


html:lang(en) {
  .orgcht {
    &__nmpr {
      span {font-size:28px;}
    }
  }
}
