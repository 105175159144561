
@media screen and (max-height:700px) {

  .mainBanner__item.item03 .mainBanner__inner {
    padding-top:90px;
  }
  .mainMrfn {
    &__wrap {
      background-size:400px;
      padding-top:120px;
      h2 {
        font-size:30px;
        margin:10px 0;
      }
      p {font-size:16px}
    }
  }
  .mainCnrs {
    &__wrap {margin-top:50px;}
    &__sub {font-size:30px}
    &__link {
      margin-top:20px;
    }
    &__list {
      width:30%;
      &--tit {font-size:20px;}
      &--txt {font-size:14px}
    }
  }
  .mainWork {
    &__txtArea {padding-top:100px}
    &__sub {font-size:35px}
  }
  .mainCnrs__list:nth-of-type(4), .mainCnrs__list:nth-of-type(5), .mainCnrs__list:nth-of-type(6) {margin-top:50px}
  .mainLocation__map {
    height:70%;
  }
  .mainBanner {
    &__tit {

    }
  }
  .mainBanner__item.item04 {
    .mainBanner {
      &__inner {padding-top:100px}
      &__th {
        font-size:13px;
        margin-top:35px;
      }
      &__sm {
        font-size:40px;
        margin-top:15px;
      }
      &__btn {margin-top:30px}
    }
  }
  .mainBanner__item.item02 {
    .mainBanner {
      &__sub {
        font-size:40px;
        margin-top:15px;
      }
      &__txt {
        font-size:13px;
      }
    }
  }
}

@media screen and (min-width:1250px) {
  .layout-top {padding:0 100px}
}