* {margin:0;padding:0;box-sizing:border-box}
/* reset */
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button {margin:0;padding:0}
fieldset,img {border:0 none}
dl,ul,ol,menu,li {list-style:none}
blockquote, q {quotes: none}
blockquote:before, blockquote:after,q:before, q:after {content:'';}
input,select,textarea,button {vertical-align:middle}
button {border:0 none;background-color:transparent;cursor:pointer}
body {background:#fff}
body,th,td,input,select,textarea,button {font-family: 'notokr-regular',"sans-serif";color:#313131} /* color값은 디자인가이드에 맞게사용 */
a {color:#313131;text-decoration:none}
i {font-style:normal}
a:active, a:hover {text-decoration:none}
address,caption,cite,code,dfn,em,var {font-style:normal;font-weight:normal}

