@charset "UTF-8";

.topBanner {
  width: 100%;
  min-width: 1200px;
  height: 130px;
  overflow: hidden;
  position: relative;
  &__close {
    display: block;
    position: absolute;
    left: 50%;
    bottom:10px;
    margin-left: 495px;
    margin-top: -16px;
    color: #fff;
    @include txtHidden();
    background:url("/images/main/txt_today.png") no-repeat 0 0;
    width:104px;
    height:18px;
    &:hover {
      border-radius: 0 !important;
    }
  }
  &__btn {
    border: 1px solid #fff;
    color: #fff;
    position: absolute;
    right: 170px;
    font-size: 15px;
    top: 50%;
    width: 180px;
    margin-top: -5px;
    line-height: 50px;
    text-align: center;
    border-radius: 15px 0;
  }
  &__inner {
    width: 1200px;
    margin: 0 auto;
  }
  &__item {
    width: 100%;
    height: 130px;
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
  }
  .bx-pager {
    position: absolute;
    z-index: 1000;
    bottom: 14px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0;
  }
  .bx-pager-item {
    display: inline-block;
    & + .bx-pager-item {
      margin-left: 8px;
    }
  }
  .bx-pager-link {
    display: block;
    @include txtHidden();
    border: 1px solid #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    &.active {
      background: #fff;
    }
  }
  .bx-controls-direction {
    a {position:absolute;top:50%;transform: translateY(-50%)}
    i {display:block;}
  }
  .bx-prev {
    left:10px;
  }
  .bx-next {
    right:10px;
  }
}

.mainNav {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  &__item {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 13px;
    width: 40px;
    line-height: 40px;
    border-bottom: 1px solid #fff;
    background: #c3ced9;
    float: right;
    clear: both;
    transition: all .3s;
    span {
      display: none;
      transition: all .3s;
    }
    &:last-child {
      border-bottom: none
    }
    &.active {
      padding: 0 20px;
      width: auto;
      background: #3d7eeb;
      border-bottom: 1px solid #3d7eeb;
      span {
        display: inline;
      }
    }
  }
}

.mainBanner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  &__wrap {
    width: 100%;
    height: 100%;
  }
  .bx-viewport, .bx-wrapper {
    position: relative;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
  }
  &__inner {
    display: inline-block;
    vertical-align: middle;
    margin-top: -130px;
  }
  &__tit {
    font-size: 22px;
    @include font("bold");
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
    display: inline-block;
  }
  &__sub {
    font-size: 60px;
    @include font("light");
    margin-top: 30px;
  }
  &__txt {
    margin-top: 30px;
    font-size: 16px;
    @include font("dLight");
    line-height: 1.5;
  }
  &__btn {
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    display: block;
    width: 190px;
    line-height: 50px;
    border-radius: 15px 0;
    font-size: 16px;
    margin:50px auto 0;
    @include font();
  }
  &__item {
    width: 100%;
    height: 100%;
    font-size: 0;
    position: relative;
    @include before-middle();
    text-align: center;
    &.item01 {
      text-align: center;
      color: #fff;
      overflow: hidden;
      .mainBanner__sub {text-transform:uppercase;}
    }
    &.item02 {
      background: url("/images/main/bg_mainBanner__item_02.jpg") no-repeat center center;
      background-size: cover;
      padding-top: 80px;
      text-align: left;
      .mainBanner {
        &__inner {
          padding-left: (300/1920*100%);
          margin-top: -70px;
        }
        &__tit {
          color: #fff;
        }
        &__sub {
          color:#fff;
          font-size: 54px;
          @include font("light");
        }
        &__txt {
          font-size: 18px;
          color: #fff;
          @include font("light");
        }
        &__btn {
          margin:60px 0 0 0;
        }
      }
    }
    &.item03 {
      background: url("/images/main/bg_mainBanner__item_03.jpg") no-repeat center center;
      background-size: cover;
      text-align: center;
      .mainBanner {
        &__item03txt {
          width:1123px;
          height:599px;
          background:url("/images/main/txt_mainBanner_item_03.png") no-repeat 0 0;
        }
        &__inner {
          padding-top: 200px;
          padding-left: 0;
        }
        &__tit {
          color: #313131;
          border-color: #313131;
        }
        &__sm {
          font-size: 50px;
          color: #1f9ea9;
          @include font("light");
          margin-top: 30px;
        }
        &__th {
          font-size: 17px;
          color: #313131;
          margin-top: 8px;
          line-height: 1.5;
          @include font("light");
          strong {
            @include font("medium");
          }
        }
      }
    }
    &.item04 {
      background: url("/images/main/bg_mainBanner__item_01.jpg") no-repeat center center;
      background-size: cover;
      text-align: center;
      .mainBanner {
        &__inner {
          padding-top: 0;
          padding-left: 0;
          margin-top:-250px;
        }
        &__tit {
          color: #313131;
          border-color: #313131;
        }
        &__sm {
          font-size: 54px;
          color: #1f9ea9;
          @include font("light");
          margin:30px auto 0;
          i {display:block}
        }
        &__th {
          font-size: 18px;
          color: #313131;
          margin-top: 40px;
          line-height: 1.5;
          @include font("light");
          strong {
            @include font("medium");
          }
        }
        &__btn {
          border-color: #313131;
          color:#313131;
          margin-top:30px;
        }
      }
    }
    &--video {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translate(-50%, -50%);
    }
  }
  &__scroll {
    position: absolute;
    left: 50%;
    margin-left: -39px;
    bottom: 20px;
    width: 78px;
    height: 78px;
    text-align: center;
    background: #c6c6c6;
    font-size: 0;
    border-radius: 50%;
    opacity: .7;
    span {
      @include txtHidden();
      position: relative;
      top: 20px;
      animation: scrollAnimate 1.5s infinite;
    }
  }

  .bx-pager {
    position: absolute;
    z-index: 1000;
    bottom: 117px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0;
  }
  .bx-pager-item {
    display: inline-block;
    & + .bx-pager-item {
      margin-left: 8px;
    }
  }
  .bx-pager-link {
    display: block;
    @include txtHidden();
    background: #7e8b9a;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    &.active {
      background: #3d7eeb;
    }
  }
}

@keyframes scrollAnimate {
  0% {
    top: 18px
  }
  50% {
    top: 28px
  }
  100% {
    top: 18px;
  }
}

.bgVr {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1200px;
  margin-left: -600px;
  height: 250px;
  background: url("/images/main/bg_vr.png") no-repeat bottom left;
}

.mainPosition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  background: #fff;
  .layout-top {
    position: relative
  }
}

.mainWork {
  width: 100%;
  height: 100%;
  position: relative;
  &__item {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &__txtArea {
    width: (480/1920*100%);
    min-width: 480px;
    height: 100%;
    float: left;
    background: #2497a4;
    padding: 150px (35/1920*100%) 150px (95/1920*100%);
  }
  &__img {
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position:relative;
    &:after {
      content:"";
      display:block;
      position:absolute;
      left:0;top:0;
      right:0;bottom:0;
      background:#5d5d7d;
      opacity: 0.15;
    }
  }
  &__tit {
    font-size: 22px;
    @include font("bold");
    color: #313131;
    height: 33px;
    border-bottom: 1px solid #313131;
    display: inline-block;
  }
  &__sub {
    color: #fff;
    font-size: 38px;
    @include font("light");
    margin-top: 25px;
  }
  &__group {
    color: #fff;
    margin-top: 25px;
    @include font("light");
    font-size: 16px;
    line-height: 1.8;
    word-break: keep-all
  }
  &__btn {
    margin-top: 25px;
    border: 1px solid #fff;
    border-radius: 15px 0;
    color: #fff;
    text-align: center;
    font-size: 16px;
    width: 190px;
    line-height: 50px;
    display: block;
  }
  &__slider {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .bx-viewport, .bx-wrapper {
    position: relative;
    width: 100%;
    height: 100% !important;
    top: 0;
    left: 0;
  }
  &__controller {
    position: absolute;
    left: (100/1920*100%);;
    bottom: 50px;
    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
  &__pager {
    display: inline-block;
    color: #fff;
    margin: 0 15px;
  }
}
.mainAdir {
  width:100%;
  height:100%;
  position:relative;
  font-size:0;
  &.-active0 {
    .mainAdir__left {background-image:url("/images/main/bg_main_step03.jpg")}
  }
  &.-active1 {
    .mainAdir__left {background-image:url("/images/main/bg_main_step03_02.jpg")}
  }
  &.-active2 {
    .mainAdir__left {background-image:url("/images/main/bg_main_step03_03.jpg")}
  }
  &__left {
    width:50%;
    height:100%;
    background-size:cover;
    display:inline-block;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &__right {
    display:inline-block;
    width:50%;
    height:100%;
    padding:150px (200/1920*100%) (100/1920*100%) (100/1920*100%);
    vertical-align: top;
  }
  h2 {
    font-size: 22px;
    @include font("bold");
    height: 32px;
    display: inline-block;
    border-bottom: 1px solid #313131;
  }
  &__group {
    display:none;
    &.-active {
      display:block;
    }
  }
  &__tab {
    display:table;
    width:100%;
    table-layout: fixed;
    margin:20px 0 60px;
    a {
      display:table-cell;
      border-bottom:2px solid #5673bc;
      padding:0 10px;
      font-size:16px;
      color:#5673bc;
      height:60px;
      vertical-align: middle;
      text-align:center;
      &.-active {
        border:2px solid #5673bc;
        border-bottom:none;
        color:#000;
        border-radius:10px 0 0 0
      }
    }
  }
  &__wrap {
    margin:-80px 0 0 -10%;
  }
  &__item {
    display:inline-block;
    width:40%;
    margin:80px 0 0 10%;
  }
  &__name {
    font-size:24px;
    line-height:55px;
    @include font("dLight");
  }
  &__thum {
    display:inline-block;
    width:55px;
    height:55px;
    line-height:55px;
    border-radius: 50%;
    background-position:center center;
    background-size:cover;
    vertical-align: middle;
    margin-right:5px;
    position:relative;
    top:-2px;
  }
  &__txt {
    width:100%;
    font-size:14px;
    margin-top:10px;
    @include font("light");
    @include ellipsis_multi(4);
    height:76px;
  }
  &__btn {
    font-size:14px;
    display:block;
    width:150px;
    height:40px;
    line-height:40px;
    background:#5673bc;
    text-align:center;
    color:#fff;
    margin-top:20px;
    border-radius:15px 0 15px 0;
  }
}
.mainMrfn {
  width: 100%;
  height: 100%;
  @include before-middle();
  text-align: center;
  position: relative;
  background: url("/images/main/bg_mrfn.png") no-repeat center center;
  background-size: cover;
  @include before-middle();
  vertical-align: middle;
  &__btn {
    width: 180px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 15px 0;
    display: block;
    margin-top: 29px;
    color:#fff;
  }
  &__wrap {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-align: left;
    padding-left: 530px;
    h1 {
      font-size: 22px;
      @include font("bold");
      height: 32px;
      display: inline-block;
      border-bottom: 1px solid #fff;
    }
    h2 {
      font-size: 50px;
      margin: 25px 0 20px;
      @include font("light");
      strong {
        @include font("medium");
      }
    }
    p {
      font-size: 18px;
      @include font("light");
      line-height: 1.7;
    }
  }
  &__link {
    width: 100%;
    height: 240px;
    display: table;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &--linkGroup {
    display: table;
    width: 100%;
  }
  &__list {
    display: table-cell;
    width: 25%;
    h2 {
      line-height: 179px;
      text-align: center;
      font-size: 18px;
      color: #fff;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    a {
      line-height: 61px;
      width: 50%;
      display: table-cell;
      text-align: center;
      color: #fff;
      font-size: 16px;
      & + a {
        border-left: 1px solid #fff;
      }
    }
    &.list01 {
      h2 {
        background-image: url("/images/main/bg_mrfn_01.jpg");
      }
      a {
        background: #f06146;
      }
    }
    &.list02 {
      h2 {
        background-image: url("/images/main/bg_mrfn_02.jpg");
      }
      a {
        background: #f1a606;
      }
    }
    &.list03 {
      h2 {
        background-image: url("/images/main/bg_mrfn_03.jpg");
      }
      a {
        background: #246ca2;
      }
    }
    &.list04 {
      h2 {
        background-image: url("/images/main/bg_mrfn_04.jpg");
      }
      a {
        background: #a8b51a;
      }
    }
  }
}

.mainCnrs {
  width: 100%;
  height: 100%;
  background:url("/images/main/bg_maincn.jpg") no-repeat center center;
  background-size:cover;
  @include before-middle();
  text-align: center;
  &__wrap {
    display: inline-block;
    vertical-align: middle;
    font-size:0;
  }
  &__tit {
    font-size: 22px;
    color: #313131;
    padding-bottom: 5px;
    margin-bottom:50px;
    border-bottom: 1px solid #313131;
    display: inline-block;
  }
  &__thum {
    width:100%;
    height:191px;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center center;
    display:block;
    position:relative;
    &+.mainCnrs__subTit {margin-top:30px !important;}
    &:before {
      content:"";
      display:block;
      width:100%;
      height:100%;
      position:absolute;
      left:0;top:0;
      background:#000;
      opacity: .3;
    }
    &:after {
      content:"";
      @include iconPlay();
      left:50%;
      top:50%;
      position:absolute;
      transform: translate(-50%,-50%);
    }
  }
  &__item {
    display:inline-block;
    width:285px;
    height:400px;
    background:#fff;
    position:relative;
    padding:70px 40px 40px 40px;
    vertical-align: top;
    transition: all .3s;
    &:after {
      content:"";
      @include iconNewMore();
      position:absolute;
      bottom:30px;
      right:40px;
    }
    &.-video {
      padding:0;
      .mainCnrs {
        &__subTit,
        &__item,
        &__txt {padding:0 40px 0}
        &__subTit,&__txt {
          @include ellipsis_multi(2);
          margin-top:10px;
        }
      }
    }
    &:hover {
      box-shadow: 2px 2px 10px rgba(0,0,0,.3);
      &:after {
        @include iconNewMore_over();
      }
      .mainCnrs {
        &__subTit,&__txt,&__data {color:#fff;}
      }
      &.-red {background:#e15a45;}
      &.-orange {background:#e69d26}
      &.-blue {background:#2f6399}
      &.-green {
        background:#a0ac2b;
        .mainCnrs__txt.-file {
          &:before {
            @include iconFile_over();
          }
        }
      }
    }
    &:not(:first-of-type) {
      margin-left:20px;
    }
    &.-red {
      .mainCnrs {
        &__type {background:#e15a45}
      }
    }
    &.-orange {
      .mainCnrs {
        &__type {background:#e69d26}
      }
    }
    &.-blue {
      .mainCnrs {
        &__type {background:#2f6399;}
      }
    }
    &.-green {
      .mainCnrs {
        &__type {background:#a0ac2b;}
      }
    }
  }
  &__roll {
    vertical-align: top;
  }
  &__type {
    position:absolute;
    left:0;top:0;
    display:inline-block;
    font-size:14px;
    color:#fff;
    padding:0 10px;
    line-height:30px;
    height:30px;
    text-align:center;
    min-width:75px;
    z-index: 10;
    @include font("light");
  }
  &__subTit {
    font-size:23px;
    @include  font("dLight");
    @include ellipsis_multi(4);
  }
  &__txt {
    font-size:14px;
    @include font("light");
    @include ellipsis_multi(4);
    margin-top:20px;
    &.-file {
      font-size:14px;
      &:before {
        content:"";
        display:inline-block;
        margin-right:5px;
        @include iconFile();
      }
    }
  }
  &__data {
    position:absolute;
    left:40px;
    bottom:40px;
    font-size:13px;
    @include font("light");
    color:#717171;
  }
}

.mainLocation {
  width: 100%;
  height: 100%;
  &__map {
    width: 100%;
    height: (730/900*100%);
    background: url("/images/main/bg_main_map.png") no-repeat center center;
    @at-root html:lang(en) {
      .mainLocation__map {
        background: url("/images/main/bg_main_map_eng.jpg") no-repeat center center;
      }
    }
  }
  &__wrap {
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
    position: relative;
    h1 {
      font-size: 30px;
      @include font("dLight");
      color: #313131;
    }
    address {
      font-size: 16px;
      display: block;
      margin-top: 20px;
      @include font("light");
      color: #313131;
      i {
        display:inline-block;
        margin:0 23px;
      }
    }
  }
  &__naver {
    position: absolute;
    right: 0;
    border: 1px solid #313131;
    width: 220px;
    line-height: 50px;
    border-radius: 15px 0;
    text-align: center;
    top: 50%;
    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }
  &__link {
    margin-top: 50px;
    width: 100%;
    border-top: 1px solid #e8e8e8;
  }
  &__inner {
    width: 1200px;
    height: 90px;
    margin: 0 auto;
    @include before-middle();
    position: relative;
    h2 {
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      @include font("dLight");
      color: #313131;
    }
    .bx-wrapper {
      float: right;
      margin-top: 18px;
      font-size: 0;
      width: 930px;
      margin-right: 40px;
      vertical-align: middle;
      img {
        vertical-align: top
      }
      .bxSlider-item {
        text-align: center;
        overflow: hidden;
      }
    }
  }
}

.mainRelateLink {
  .bx-controls-direction {
    a {
      display: block;
      @include txtHidden();
      position: absolute;
      top: 50%;
      margin-top: -17px;
    }
    .bx-prev {
      @extend .iconBannerPrev;
      left: 195px;
    }
    .bx-next {
      @extend .iconBannerNext;
      right: 0;
    }
  }
}

.engPopup {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 2000;
  top: 0; left: 0;
  text-align: center;
  min-width: 550px;
  font-size: 0;
  @include before-middle();
  &__content {
    width: 600px;
    display: inline-block;
    background: #fff;
    vertical-align: middle;
    position: relative;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
    border-top: 1px solid #d3d5d6;
  }
  &__img {
    padding-top: 46px;
  }
  &__tit {
    font-size: 32px;
    font-weight: 400;
    color: #313131;
    margin-top: 27px;
    &.-eng {
      font-size: 26px;
      margin-top: 4px;
    }
  }
  &__text {
    font-size: 17px;
    line-height: 28px;
    color: #313131;
    font-weight: 300;
    margin-top: 23px;
    &.-eng {
      font-size: 14px;
      margin-top: 4px;
      color: #717171;
      line-height: 22px;
    }
  }
  &__stext {
    font-size: 14px;
    line-height: 20px;
    color: #717171;
    font-weight: 300;
    &:before {
      content: '';
      display: block;
      background: #5673bc;
      width: 50px; height: 2px;
      margin: 33px auto 15px auto;
    }
  }
  &__btn {
    display: block;
    width: 100%;
    margin-top: 49px;
  }
  &__link {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    width: 100%;
    padding-bottom: 27px;
    @include before-middle();
    &.-link01 {background: #367df3 ;
    }
    .-eng {
      font-size: 15px;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }
  &__close {
    float: right;
    right: 30px; top: 30px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
  }
}

.mainOrganization {
  width:100%;
  height:100%;
  @include before-middle();
  text-align:center;
  &__tit {
    text-align:center;
    display:inline-block;
    padding-bottom:10px;
    font-size:22px;
    @include font("bold");
    color:#313131;
    border-bottom:1px solid #313131;
  }
  &__wrap {
    display:inline-block;
    text-align:center;
    vertical-align: middle;
    margin-top:80px;
  }
  &__img {
    width:1201px;
    height:502px;
    background:url("/images/main/img_main_organ.jpg") no-repeat 0 0;
  }
  &__type {
    font-size:0;
    margin:40px 0;
    li {
      display:inline-block;
      font-size:32px;
      @include font("light");
      color:#313131;
      &:not(:first-child) {
        margin-left:25px;
      }
    }
  }
  &__details {
    text-align:center;
    font-size:0;
    h3 {
      display:inline-block;
      font-size:16px;
      @include font("light");
      margin-right:10px;
      &:before {
        content:"";
        display:inline-block;
        width:26px;
        height:14px;
        background:url("/images/main/iconMain_org.png") no-repeat 0 0;
        margin-right:5px;
      }
    }
  }
  &__btn {
    display:inline-block;
    width:228px;
    line-height:50px;
    border:1px solid #313131;
    color:#313131;
    font-size:16px;
    border-radius:15px 0 15px 0;
    &:after {
      content:"";
      display:inline-block;
      margin-left:10px;
      @include iconNextPage();
    }
    &:not(:first-of-type) {
      margin-left:10px;
    }
  }
}

html:lang(en) {
  .mainBanner__item.item01 {
    .mainBanner__sub {font-size:50px}
    .mainBanner__txt {font-size:18px}
  }
  .mainBanner__item.item04 .mainBanner__sm,
  .mainBanner__item.item02 .mainBanner__sub {font-size:48px}
  .mainLocation__wrap address i {margin:0 15px}
  .mainBanner__item.item03 .mainBanner__item03txt {background:url("/images/main/txt_mainBanner_item_03_eng.png") no-repeat 0 0 ;}
}


@media all and (max-width:1366px) {
  .mainWork__txtArea {
    width:50%;
    padding: 200px (95/1920*100%) 150px (95/1920*100%);
  }
  .mainBanner__sub {font-size:46px}
  .mainBanner__txt {margin-top:20px}
  .mainBanner__item.item04 .mainBanner__th {margin-top:20px}
  .mainBanner__item.item04 .mainBanner__sm i {display:inline-block}
  .mainBanner__item.item04 .mainBanner__sm {font-size:40px}
  .mainBanner__item.item04 .mainBanner__th {font-size:16px;}
  .mainBanner__item.item02 .mainBanner__sub {font-size:46px}
  .mainBanner__item.item02 .mainBanner__inner {padding-left:7.625%}
  .mainBanner__item.item02 .mainBanner__txt {font-size:16px}
  .mainBanner__item.item02 .mainBanner__btn {margin-top:30px}
  .mainBanner__item.item03 .mainBanner__item03txt {
    width:729px;
    height:447px;
    background:url("/images/main/txt_mainBanner_item_sm.png") no-repeat 0 0 ;
  }
  .mainBanner__item.item03 .mainBanner__inner {
    padding-top:100px;
  }
  .mainWork__sub {font-size:32px;}
  .mainWork__group {margin-top:20px;}
  .mainAdir__name {letter-spacing: -2px;}
  .mainAdir__txt {
    @include ellipsis_multi();
  }
  .mainAdir__item {
    margin-top:30px;
  }
  .mainAdir__wrap {margin-top:-70px;}
  .mainAdir__right {
    padding-right:(100/1920*100%);
  }
  html:lang(en) {
    .mainBanner__item.item03 .mainBanner__item03txt {
      width:870px;
      height:464px;
      margin-top:80px;
      background:url("/images/main/txt_mainBanner_item_sm_eng.png") no-repeat 0 0 ;
    }
    .mainBanner__tit {font-size:18px}
    .mainBanner__sub {font-size:38px}
    .mainBanner__txt {font-size:14px;}
    .mainBanner__item.item04 .mainBanner__sm {font-size:36px}
    .mainBanner__item.item04 .mainBanner__th {font-size:14px}
    .mainBanner__item.item02 .mainBanner__sub {font-size:36px}
    .mainBanner__item.item02 .mainBanner__txt {font-size:14px}
    .mainOrganization__details h3 {font-size:14px}
    .mainOrganization__btn {
      font-size:14px;
      width:208px;
      line-height:45px;
      height:45px;
    }
    .mainOrganization__tit {font-size:18px}
    .mainOrganization__img {
      width:900px;
      height:339px;
      background:url("/images/main/img_main_organ_sm.png") no-repeat 0 0;
      margin-bottom:20px;
    }
    .mainOrganization__type {
      margin:20px 0;
      li {font-size:26px}
    }
  }
}















