@charset "UTF-8";

.map {
  &__api {
    width:100%;
    height:550px;
    border:1px solid #d6d6d6;
    margin-bottom:45px;
  }
  &__address,&__phone {
    font-size:18px;
    @include font("dLight");
    line-height:1.7
  }
  &__phone {
    margin-bottom:40px;
  }
  &__type {
    font-size:0;
    width:100%;
    @include before-middle();
    &Left,&Right {
      display:inline-block;
      vertical-align: middle;
    }
    &Right {
      font-size:18px;
      @include font("dLight");
      margin-left:30px;
    }
    &+& {
      margin-top:40px;
    }
    &List {
      line-height:1.5;
      @include font("dLight");
      strong {@include font("medium");}
      &:before {
        top:10px !important;
      }
    }
  }
}


html:lang(en) {
  .map {
    &__typeList {font-size:16px}
    &__typeRight {
      width:875px;
    }
    &__address,&__phone {
      font-size:16px;
    }
    .global__subTit.-size20 {@include font("medium")}
  }
}