@charset "UTF-8";

.grayBox {
  background:#f6f6f6;
  padding:40px 30px;
  li {
    line-height:1.5;
    font-size:16px;
    color:#313131;
    @include font("dLight");
  }
  ul {
    margin-left:20px;
  }
  &__tit {
    font-size:16px;
    @include font("medium");
    margin-top:50px;
  }
  &__txt {
    font-size:16px;
    @include font("dLight");
    margin-top:20px;
    line-height:1.5;
  }
  &__ol {
    margin-top:40px;
  }
}