@charset "UTF-8";

.infoList {
  clear:both;
  & + & {margin-top:50px}
  &.-top {
    width:100%;
    border-bottom:2px solid #eceaeb;
    padding-bottom:40px;
    overflow:hidden;
    margin-bottom:45px;
    .infoList {
      &__thum {
        width:100%;
        height:264px;
        border-radius:15px 0 15px;
        border:1px solid #d6d6d6;
      }
      &__subTit {
        margin-top:30px;
      }
      &__careerItem {
        font-size:16px;
      }
    }
  }
  &__thum {
    width:100%;
    height:225px;
    overflow:hidden;
    background-size:cover;
    background-position:center top;
    background-repeat: no-repeat;
    background-color:#f6f6f6;
  }
  &__left {
    float:left;
    width:230px;
    overflow:hidden;
  }
  &__right  {
    overflow:hidden;
    padding-left:35px;
  }
  &__tit {
    font-size:30px;
    @include font("dLight");
    color:#3173e6;
    margin-bottom:10px;
    line-height:1;
  }
  &__name {
    font-size:22px;
    @include font("medium");
    margin-bottom:10px;
  }
  &__type {
    font-size:16px;
    @include font("dLight");
    word-break: keep-all;
    letter-spacing: -0.035em;
  }
  &__subTit {
    font-size:18px;
    @include font("medium");
    margin:20px 0 10px;
  }
  &__career {
    height:80px;
    overflow-y:auto;
  }
  &__careerItem {
    font-size:15px;
    @include font("dLight");
    word-break: keep-all;
    position:relative;
    &:not(:first-child) {
      margin-top:5px;
    }

  }
  &__moreBtn {
    width:195px;
    border:1px solid #313131;
    font-size:14px;
    height:40px;
    line-height:40px;
    text-align:center;
    display:block;
    color:#313131;
    border-radius:15px 0;
    transition: all .3s;
    position:absolute;
    left:20px;
    bottom:20px;
    &:hover {
      border-radius:0 15px
    }
  }
  &__info {
    padding:0 20px 20px;
    position:relative;
  }
  &__row {
    font-size:0;
    display:block;
    margin:0 0 0 -20px;
  }
  &__item {
    width:235px;
    height:470px;
    border-radius:15px 0 15px;
    border:1px solid #d6d6d6;
    display:inline-block;
    margin:20px 0 0 20px;
    overflow:hidden;
    vertical-align: top;
    position:relative;
    &.-btn {
      height:530px;
      .infoList {
        &__info {
          padding-bottom:80px;
        }
      }
    }
  }
  &__popupTit {
    font-size:30px;
    @include font();
    margin-bottom:10px;
  }
  @at-root .layerPopup {
    .infoList.-top {
      margin-bottom:30px;
      .infoList {
        &__tit {
          font-size:22px;
          margin-bottom:5px;
        }
        &__thum {
          width:100%;
          height:224px;
        }
      }
    }
  }
  &__footer {
    width:100%;
    background:#f6f6f6;
    border-top:2px solid #eceaeb;
    text-align:center;
    padding:30px 0;
    font-size:14px;
  }
  &__mail {
    font-size:16px;
    @include font("medium");
    margin-top:5px;
  }
}

html:lang(en) {
  .infoList {
    &__tit {
      font-size:28px;
      @include font("dLight");
    }
    &__type {
      font-size:13px;
      line-height:1.4;
    }
    &__careerItem {
      font-size:13px;
      line-height:1.4;
    }
    &__item {
      height:515px;
    }
    &__career {
      height:110px;
    }
    &__item.-leader {
      .infoList__subTit {
        &:first-of-type {
          @include font();
          strong {@include font("medium")}
        }
      }
    }
  }

}