@charset "UTF-8";
//게시판 리스트형
.bbsList {
  &__item {
    display:block;
    width:100%;
    height:126px;
    background:#f6f6f6;
    position:relative;
    border:2px solid #f6f6f6;
    @include before-middle();
    transition: all .3s;
    &:after {
      content:"";
      display:block;
      position:absolute;
      right:60px;
      top:50%;
      margin-top:-10px;
      @extend .iconBbsList
    }
    &:hover {
      background:#fff;
      border:1px solid #9e9e9e;
      &:after {
        @extend .iconBbsListActive;
      }
    }
    &+.bbsList__item {margin-top:10px}
  }
  &__num,&__txtArea {display:inline-block;vertical-align: middle}
  &__num {
    font-size:30px;
    width:145px;
    text-align:center;
    @include font("light");
  }
  &__txtArea {
    width:770px;
    h2 {
      font-size:20px;
      width:95%;
      @include font("dLight");
      @include ellipsis();
      margin-bottom:5px;
    }
  }
  &__name,
  &__data,
  &__count {
    display:inline-block;
    font-size:14px;
    @include font("dLight");
    color:#717171;
  }
  &__data,&__count {
    margin-left:20px;
    position:relative;
    &:before {
      content:"|";
      display:block;
      position:absolute;
      left:-15px;
      top:1px;
      font-size:12px;
    }
  }
  &__empty {
    text-align:center;
    padding:200px 0;
    border-top:1px solid #d4d4d4;
    border-bottom:1px solid #d4d4d4;
  }
  &__type {
    display:inline-block;
    vertical-align: middle;
    line-height:30px;
    min-width:80px;
    padding:0 10px;
    color:#fff;
    font-size:13px;
    @include font();
    margin-right:10px;
    text-align:center;
    background:#2497a4;
    position:relative;
    z-index: 2;
  }

}

//게시판 뷰
.bbsView {
  position:relative;
  &__header {
    display:table;
    width:100%;
  }
  &__type {
    display:table-cell;
    vertical-align: top;
    padding-right:15px;
    width:92px;
  }
  &__tit {
    display:table-cell;
    vertical-align: top;
    .-tit {
      font-size:32px;
      @include font();
    }
  }
  &__info {
    margin-top:20px;
    font-size:0;
    li {
      font-size:16px;
      display:inline-block;
      vertical-align: middle;
      &:not(:first-child){
        &:before {
          content:"";
          display:inline-block;
          width:1px;
          height:13px;
          background:#313131;
          margin:0 10px;
          position:relative;
          top:1px;
        }
      }
      &.-bold {
        @include font("bold");
      }
    }

  }
  &__data {font-size:14px;display:block;}
  &__content {
    border-top:1px solid #eceaeb;
    border-bottom:1px solid #eceaeb;
    padding:50px 0;
    margin-top:20px;
    min-height:400px;
    img {max-width:100%;height:auto !important;margin:10px 0;}
    font-size:18px;
    @include font("dLight");
    line-height:1.5;
  }
  &__file {
    margin-top:20px;
    min-height:59px;
    border-top:1px solid #eceaeb;
    padding:20px 0 20px 80px;
    position:relative;
    font-size:0;
    @include font("dLight");
    &+.bbsView__content {margin-top:0}
  }
  &__fileName {
    font-size:16px;
    position:absolute;
    top:20px;
    left:0;
  }
  &__fileList {
    font-size:16px;
    display:block;
    color:#313131;
    vertical-align: middle;
    @include ellipsis();
    line-height:1.2;
    &:not(:first-of-type) {
      margin-top:10px;
    }
    &:before {
      content:"";
      display:inline-block;
      @include iconFile();
      margin-right:5px;
    }
  }
  &__fileList:last-of-type {
    &:after {display:none}
  }
  &__nav {
    position:absolute;
    right:0;
    top:10px;
    font-size:0;
    a {
      display:inline-block;
      border:1px solid #313131;
      width:50px;
      line-height:48px;
      text-align:center;
      border-radius:4px;
      vertical-align: middle;
      &+a {margin-left:8px}
    }
    span {
      @include txtHidden();
      vertical-align: middle;
    }
  }
  &+.btnGroup {
    margin-top:70px;
  }
  &.-answer {
    .bbsView__content {
      border-bottom:none;
      padding-bottom:0;
    }
  }
  &.-hasType {
    .bbsView {
      &__header {
        display:block;
      }
      &__type {
        display:block;
        margin-bottom:5px;
        width:auto;
      }
      &__tit {
        display:block;
      }
    }
    .bbsList {
      &__type {
        background:#246ca2;
        display:inline-block;
        width:auto;
      }
    }
  }
}

//포토갤러리
.photoList {
  overflow:hidden;
  margin:-30px 0 0 -35px;
  &__item {
    width:310px;
    height:305px;
    display:block;
    float:left;
    margin-left:35px;
    margin-top:30px;
  }
  &__thum {
    overflow:hidden;
    background-position:center center;
    background-repeat: no-repeat;
    background-size:cover;
    height:210px;
    width:100%;
    position:relative;
    &:before {
      content:"";
      display:block;
      position:absolute;
      left:0;
      top:0;
      background:#000;
      opacity: .3;
      width:100%;
      height:100%;
    }
  }
  &__type {
    position:absolute;
    left:0;
    top:0;
    font-size:13px;
    line-height:30px;
    padding:0 10px;
  }
  &__txt {
    margin-top:15px;
    font-size:18px;
    @include font("dLight");
    @include ellipsis_multi(2);
    width:100%;
  }
  &__data {
    font-size:14px;
    @include font("dLight");
    color:#717171;
    margin-top:15px;
  }
  &.-video {
    .photoList__thum:after {
      content:"";
      display:block;
      @include iconPlay();
      position:absolute;
      left:50%;
      top:50%;
      margin:-27px 0 0 -27px;
    }
  }
}
//게시판 리스트 상단 텍스트
.subContentsTitle{
  width: 1000px;
  margin: 0 auto 50px;
  border-bottom:2px solid #3173e6;
  padding-bottom: 30px;
  display: table;
  &__img{
    display: table-cell;
    width: 230px;
    text-align: right;
  }
  &__txt{
    display: table-cell;
    vertical-align: middle;
    padding-left: 30px;
    font-size: 18px;
    line-height: 28px;
  }
}

.bbsVideo__frame {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}