@charset "UTF-8";

//서브비쥬얼
.subVisual {
  width: 100%;
  height: 130px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 60px;
  @include before-middle();
  &__nav {
    display: block;
    font-size: 20px;
    padding-bottom: 5px;
    @include font();

  }
  &__tit {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 40px;
    @include font("dLight");
  }
  &--bgActivity {
    background-image: url("/images/comm/bg_subVisual_activity.jpg");
  }
  &--bgInfo {
    background-image: url("/images/comm/bg_subVisual_info.jpg");
  }
  &--bgAtc {
    background-image: url("/images/comm/bg_subVisual_01.jpg");
  }
  &--bgMlrd {
    background-image: url("/images/comm/bg_subVisual_02.jpg");
  }
  &--bgOrgcht {
    background-image: url("/images/comm/bg_subVisual_03.jpg");
  }
  &--bgDir {
    background-image: url("/images/comm/bg_subVisual_dir.jpg");
  }
  &--bgPropse {
    background-image: url("/images/comm/bg_subVisual_propse.jpg");
    &.-big {
      height:auto;
      padding:50px 0 30px;
      .subVisual {
        &__tit {
          font-size: 22px;
          line-height: 1;
          height: 32px;
          vertical-align: top;
          border-bottom: 2px solid #fff;
          @include font("bold");
          margin-bottom: 25px;
        }
        &__subTit {
          font-size: 50px;
          @include font();
          line-height: 1;
          margin-bottom: 15px;
        }
        &__txt {
          font-size: 16px;
          @include font("light");
          line-height: 1.5;
        }
      }
    }
  }
  &__wrap {
    width: 1000px;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    text-align: left;
  }
  &__btn {
    display: block;
    width: 208px;
    height: 50px;
    border: 2px solid #fff;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-top: 30px;
    border-radius: 15px 0;
  }
}

html:lang(en) {
  .subVisual {
    &__nav {font-size:16px}
    &__tit {
      font-size:36px;
    }
  }
}

