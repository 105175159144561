@charset "UTF-8";

//버튼그룹
.btnGroup {
  font-size:0;
  text-align:center;
  @include clearFix();
  &__btn {
    min-width:180px;
    line-height:50px;
    text-align:center;
    border:1px solid #313131;
    font-size:16px;
    border-radius:15px 0;
    display:inline-block;
    &.-middle {
      width:208px;
    }
  }
  &__btn+&__btn {
    margin-left:10px;
  }
  &--long {
    padding:0 50px;
    min-width:278px;
  }
  &--right {
    float:right;
  }
}

.btn {
  &--blue {
    background:#5673bc;
    color:#fff;
    border-color:#5673bc;
  }
}


//버튼공통 효과
[class*="btn"] {
  transition: all 300ms;
  &:hover {
    border-radius:0 15px;
  }
}

.bgBlue {background:#5673bc;border-color:#5673bc;color:#fff;}
.bgDarkBlue {background:#246ca2;border-color:#246ca2;color:#fff;}
.bgDarkGreen {background:#a8b51a;border-color:#a8b51a;color:#fff}
.bgRed {color:#fff;background:#f06146;border-color:#f06146;}
.bgYellow {color:#fff;background:#f1a606;border-color:#f1a606;}