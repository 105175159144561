@charset "UTF-8";

.tabs {
  width:1000px;
  margin:0 auto 60px;
  display:table;
  table-layout: fixed;
  border:1px solid #838a9b;
  border-left:none;
  border-bottom:2px solid #838a9b;
  &.-one {
    border: none;
    border-bottom:2px solid #838a9b;
    display:block;
    .tabs__item {
      padding-left:38px;
      padding-right:38px;
    }
  }
  &__item {
    display:table-cell;
    text-align:center;
    border-left:1px solid #838a9b;
    padding:10px 0;
    font-size:17px;
    @include font("light");
    &.-active {
      color:#fff;
      background:#838a9b;
    }
    &.-one {
      width: 200px;
      display: inline-block;
    }
  }
  &.-fontSizeSmall {
    .tabs__item {font-size:17px}
  }
}